import React from "react"
import Navbar from "./navbar"
import Footer from "./footer"
import Meta from "./meta"

const Layout = ({ children }) => (
  <>
    <div className="flex flex-col h-screen">
    <Meta />
    <Navbar />
    <div className="flex-1 overflow-y-auto">
    {children}
    </div>
    <Footer />
    </div>
  </>
)

export default Layout