import React from "react"
import { PhoneIcon } from "@heroicons/react/outline"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { graphql } from "gatsby"


const Home = ({ data }) => (
<Layout>
  <div>
    {/* Announcement */}
    <section>
      <div className="bg-blue-600 text-white py-8">
        <div className="container text-center">
        <div className="text-lg" dangerouslySetInnerHTML={{
          __html: data.announcement.data.Text.childMarkdownRemark.html,
        }} />
        </div>
      </div>
    </section>
    {/* End Announcement */}
  <div className="container">
    {/* Intro */}
    <section>
    <div className="two-cols mt-20">
      <div className="m-auto text-center">
      <StaticImage 
        src="../images/cdc-logo.png"
        alt="CDC Transportation."
        width={300}
      />
      </div>
      <div className="m-auto text-center md:text-left">
      <div className="text-lg mt-3" dangerouslySetInnerHTML={{
        __html: data.introText.data.Text.childMarkdownRemark.html,
      }} />
      <div className="font-bold" dangerouslySetInnerHTML={{
        __html: data.introAttr.data.Text.childMarkdownRemark.html,
      }} />
      <a href="tel:2168005579"><button className="btn mt-3">Reserve your ride <PhoneIcon className="h-6 w-6 inline" /></button></a>
      </div>
    </div>
    </section>
    {/* End Intro */}
  </div>

  {/* About */}
  <div className="bg-gray-100 mt-12">
    <div className="container pt-8 pb-6 two-cols">
      <div>
      <GatsbyImage image={data.missionPhoto.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.missionPhoto.data.Alt} className="rounded-lg mb-3" />
      <h2>Our Mission</h2>
      <div dangerouslySetInnerHTML={{
        __html: data.missionText.data.Text.childMarkdownRemark.html,
      }} />
      </div>
      <div>
      <GatsbyImage image={data.visionPhoto.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.visionPhoto.data.Alt} className="rounded-lg mb-3" />
      <h2>Our Vision</h2>
      <div dangerouslySetInnerHTML={{
        __html: data.visionText.data.Text.childMarkdownRemark.html,
      }} />
      </div>
    </div>

  </div>
  {/* End About */}

  <section className="bg-downtown h-half mt-20 bg-center hidden lg:block"></section>

  {/* Services */}
  <section>
  <div className="bg-gray-100 mt-20 grid grid-cols-1 md:grid-cols-2">
    <div className="py-6 px-6 xl:px-24 m-auto order-2 md:order-1">
      <h2>Services Provided</h2>
      <div className="mb-0" dangerouslySetInnerHTML={{
        __html: data.serviceText.data.Text.childMarkdownRemark.html,
      }} />
      <a href="tel:2168005579"><button className="btn mt-3">Reserve Now <PhoneIcon className="h-6 w-6 inline" /></button></a>
    </div>
    <GatsbyImage image={data.servicePhoto.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.servicePhoto.data.Alt} className="h-half order-1 md:order-2" />
  </div>
  </section>
  {/* End Services */}

  {/* Certifications */}
  <section>
    <div className="my-12 container">
    <h2>Certifications</h2>
    <div className="grid grid-cols-3 gap-8 mt-8">
      <div className="m-auto text-center">
      <GatsbyImage image={data.certOne.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.certOne.data.Alt} />
      </div>
      <div className="m-auto text-center">
      <GatsbyImage image={data.certTwo.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.certTwo.data.Alt} />
      </div>
      <div className="m-auto text-center">
      <GatsbyImage image={data.certThree.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.certThree.data.Alt} />
      </div>
    </div>
    </div>
  </section>
  {/* End Certifications */}
  
  {/* CTA */}
  <section>
  <div className="bg-gray-100 mt-20 grid grid-cols-1 md:grid-cols-2 gap-x-8">
    <GatsbyImage image={data.ctaPhoto.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.ctaPhoto.data.Alt} className="h-st" />
    <div className="p-6 m-auto">
      <h2>Reserve your ride today!</h2>
      <div className="mb-0" dangerouslySetInnerHTML={{
        __html: data.ctaText.data.Text.childMarkdownRemark.html,
      }} />
      <a href="tel:2168005579"><button className="btn mt-3">Reserve Now <PhoneIcon className="h-6 w-6 inline" /></button></a>
    </div>
  </div>
  </section>
  {/* End CTA */}

  {/* Reviews */}
  <section>
  <div className="container my-12">
  <h2>Reviews</h2>
  <div className="grid grid-cols-none w-2/3 mx-auto lg:w-full lg:grid-cols-3 lg:gap-8">
    <div className="review">
    <p><span className="font-serif">“</span>{data.reviewOne.data.Plain}<span className="font-serif">”</span></p>
    <p><span className="text-blue-600 font-bold text-xl">{data.reviewAttrOne.data.Plain}</span><br /><span className="text-sm font-bold">{data.reviewDateOne.data.Date}</span></p>
    </div>
    <div className="review">
    <p><span className="font-serif">“</span>{data.reviewTwo.data.Plain}<span className="font-serif">”</span></p>
    <p><span className="text-blue-600 font-bold text-xl">{data.reviewAttrTwo.data.Plain}</span><br /><span className="text-sm font-bold">{data.reviewDateTwo.data.Date}</span></p>
    </div>
    <div className="review">
    <p><span className="font-serif">“</span>{data.reviewThree.data.Plain}<span className="font-serif">”</span></p>
    <p><span className="text-blue-600 font-bold text-xl">{data.reviewAttrThree.data.Plain}</span><br /><span className="text-sm font-bold">{data.reviewDateThree.data.Date}</span></p>
    </div>
    {data.reviewFour.data.Plain && (
    <div className="review">
    <p><span className="font-serif">“</span>{data.reviewFour.data.Plain}<span className="font-serif">”</span></p>
    <p><span className="text-blue-600 font-bold text-xl">{data.reviewAttrFour.data.Plain}</span><br /><span className="text-sm font-bold">{data.reviewDateFour.data.Date}</span></p>
    </div>
    )}
    {data.reviewFive.data.Plain && (
    <div className="review">
    <p><span className="font-serif">“</span>{data.reviewFive.data.Plain}<span className="font-serif">”</span></p>
    <p><span className="text-blue-600 font-bold text-xl">{data.reviewAttrFive.data.Plain}</span><br /><span className="text-sm font-bold">{data.reviewDateFive.data.Date}</span></p>
    </div>
    )}
    {data.reviewSix.data.Plain && (
    <div className="review">
    <p><span className="font-serif">“</span>{data.reviewSix.data.Plain}<span className="font-serif">”</span></p>
    <p><span className="text-blue-600 font-bold text-xl">{data.reviewAttrSix.data.Plain}</span><br /><span className="text-sm font-bold">{data.reviewDateSix.data.Date}</span></p>
    </div>
    )}
  </div>
  </div>
  </section>
  {/* End Reviews */}

  {/* Gallery */}
  <section className="bg-gray-100">
  <div className="container mt-12 py-8">
  <h2>Gallery</h2>
  <div className="grid grid-cols-2 lg:grid-cols-3 gap-8 mt-5">
    <div>
    <GatsbyImage image={data.galleryPhotoOne.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.galleryPhotoOne.data.Alt} className="rounded-lg shadow" />
    {data.galleryCapOne.data.Plain && (
      <p className="pt-2 italic text-sm">{data.galleryCapOne.data.Plain}</p>
    )}
    </div>
    <div>
    <GatsbyImage image={data.galleryPhotoTwo.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.galleryPhotoTwo.data.Alt} className="rounded-lg shadow" />
    {data.galleryCapTwo.data.Plain && (
      <p className="pt-2 italic text-sm">{data.galleryCapTwo.data.Plain}</p>
    )}
    </div>
    <div>
    <GatsbyImage image={data.galleryPhotoThree.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.galleryPhotoThree.data.Alt} className="rounded-lg shadow" />
    {data.galleryCapThree.data.Plain && (
      <p className="pt-2 italic text-sm">{data.galleryCapThree.data.Plain}</p>
    )}
    </div>
    {data.galleryPhotoFour.data.Alt && (
    <div>
    <GatsbyImage image={data.galleryPhotoFour.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.galleryPhotoFour.data.Alt} className="rounded-lg shadow" />
    {data.galleryCapFour.data.Plain && (
      <p className="pt-2 italic text-sm">{data.galleryCapFour.data.Plain}</p>
    )}
    </div>
    )}
    {data.galleryPhotoFive.data.Alt && (
    <div>
    <GatsbyImage image={data.galleryPhotoFive.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.galleryPhotoFive.data.Alt} className="rounded-lg shadow" />
    {data.galleryCapFive.data.Plain && (
      <p className="pt-2 italic text-sm">{data.galleryCapFive.data.Plain}</p>
    )}
    </div>
    )}
    {data.galleryPhotoSix.data.Alt && (
    <div>
    <GatsbyImage image={data.galleryPhotoSix.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.galleryPhotoSix.data.Alt} className="rounded-lg shadow" />
    {data.galleryCapSix.data.Plain && (
      <p className="pt-2 italic text-sm">{data.galleryCapSix.data.Plain}</p>
    )}
    </div>
    )}
    {data.galleryPhotoSeven.data.Alt && (
    <div>
    <GatsbyImage image={data.galleryPhotoSeven.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.galleryPhotoSeven.data.Alt} className="rounded-lg shadow" />
    {data.galleryCapSeven.data.Plain && (
      <p className="pt-2 italic text-sm">{data.galleryCapSeven.data.Plain}</p>
    )}
    </div>
    )}
    {data.galleryPhotoEight.data.Alt && (
    <div>
    <GatsbyImage image={data.galleryPhotoEight.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.galleryPhotoEight.data.Alt} className="rounded-lg shadow" />
    {data.galleryCapEight.data.Plain && (
      <p className="pt-2 italic text-sm">{data.galleryCapEight.data.Plain}</p>
    )}
    </div>
    )}
    {data.galleryPhotoNine.data.Alt && (
    <div>
    <GatsbyImage image={data.galleryPhotoNine.data.File.localFiles[0].childImageSharp.gatsbyImageData} alt={data.galleryPhotoNine.data.Alt} className="rounded-lg shadow" />
    {data.galleryCapNine.data.Plain && (
      <p className="pt-2 italic text-sm">{data.galleryCapNine.data.Plain}</p>
    )}
    </div>
    )}
  </div>
  </div>
  </section>
  {/* End Gallery */}

  {/* Contact */}
  <section className="bg-cleveland bg-center">
  <div className="container py-12">
  <h2 className="text-white">Contact Us</h2>
  <p className="text-white text-base lg:text-lg mb-8 w-2/3 lg:w-full font-bold">Customer&rsquo;s are our first priority, call to reserve your pick up.<br />Hours may vary upon the customer&rsquo;s request.</p>
  <div className="grid grid-cols-none lg:grid-cols-2 lg:gap-8">
    <div className="shadow p-6 pb-3 rounded-lg mb-5 lg:mb-auto bg-gray-50">
      <h3>Contact Information</h3>
      <p>Location: Elyria, OH, United States</p>
      <p>Phone: <a href="tel:2168005579">(216) 800-5579</a></p>
      <p>Email: <a href="mailto:cdctransportation@gmail.com">cdctransportation@gmail.com</a></p>
    </div>
    <div className="shadow p-6 pb-3 rounded-lg bg-gray-50">
      <h3>Hours</h3>
      <p>Sunday though Thursday: 6 a.m. to 6 p.m.</p>
      <p>Friday and Staurday: 6 a.m. to 10 p.m.</p>
    </div>
  </div>
  </div>
  </section>
  {/* End Contact */}
</div>
</Layout>
)
export default Home;

export const query = graphql`
{
  announcement:airtable(data: {ID: {eq: 1}}) {
    data {
      Text {
        childMarkdownRemark {
          html
        }
      }
    }
  }

  introText:airtable(data: {ID: {eq: 2}}) {
    data {
      Text {
        childMarkdownRemark {
          html
        }
      }
    }
  }

  introAttr:airtable(data: {ID: {eq: 3}}) {
    data {
      Text {
        childMarkdownRemark {
          html
        }
      }
    }
  }

  missionPhoto:airtable(data: {ID: {eq: 4}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 800, height: 500)
          }
        }
      }
      Alt
    }
  }

  missionText:airtable(data: {ID: {eq: 5}}) {
    data {
      Text {
        childMarkdownRemark {
          html
        }
      }
    }
  }

  visionPhoto:airtable(data: {ID: {eq: 6}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 800, height: 500)
          }
        }
      }
      Alt
    }
  }

  visionText:airtable(data: {ID: {eq: 7}}) {
    data {
      Text {
        childMarkdownRemark {
          html
        }
      }
    }
  }

  serviceText:airtable(data: {ID: {eq: 8}}) {
    data {
      Text {
        childMarkdownRemark {
          html
        }
      }
    }
  }

  servicePhoto:airtable(data: {ID: {eq: 9}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      Alt
    }
  }

  certOne:airtable(data: {ID: {eq: 10}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 192)
          }
        }
      }
      Alt
    }
  }

  certTwo:airtable(data: {ID: {eq: 11}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 192)
          }
        }
      }
      Alt
    }
  }

  certThree:airtable(data: {ID: {eq: 12}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 192)
          }
        }
      }
      Alt
    }
  }

  ctaPhoto:airtable(data: {ID: {eq: 16}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      Alt
    }
  }

  ctaText:airtable(data: {ID: {eq: 13}}) {
    data {
      Text {
        childMarkdownRemark {
          html
        }
      }
    }
  }

  reviewOne:airtable(data: {ID: {eq: 17}}) {
    data {
      Plain
    }
  }

  reviewAttrOne:airtable(data: {ID: {eq: 18}}) {
    data {
      Plain
    }
  }

  reviewDateOne:airtable(data: {ID: {eq: 19}}) {
    data {
      Date(formatString: "M.D.YYYY")
    }
  }

  reviewTwo:airtable(data: {ID: {eq: 20}}) {
    data {
      Plain
    }
  }

  reviewAttrTwo:airtable(data: {ID: {eq: 21}}) {
    data {
      Plain
    }
  }

  reviewDateTwo:airtable(data: {ID: {eq: 22}}) {
    data {
      Date(formatString: "M.D.YYYY")
    }
  }

  reviewThree:airtable(data: {ID: {eq: 23}}) {
    data {
      Plain
    }
  }

  reviewAttrThree:airtable(data: {ID: {eq: 24}}) {
    data {
      Plain
    }
  }

  reviewDateThree:airtable(data: {ID: {eq: 25}}) {
    data {
      Date(formatString: "M.D.YYYY")
    }
  }

  reviewFour:airtable(data: {ID: {eq: 47}}) {
    data {
      Plain
    }
  }

  reviewAttrFour:airtable(data: {ID: {eq: 48}}) {
    data {
      Plain
    }
  }

  reviewDateFour:airtable(data: {ID: {eq: 49}}) {
    data {
      Date(formatString: "M.D.YYYY")
    }
  }

  reviewFive:airtable(data: {ID: {eq: 50}}) {
    data {
      Plain
    }
  }

  reviewAttrFive:airtable(data: {ID: {eq: 51}}) {
    data {
      Plain
    }
  }

  reviewDateFive:airtable(data: {ID: {eq: 52}}) {
    data {
      Date(formatString: "M.D.YYYY")
    }
  }

  reviewSix:airtable(data: {ID: {eq: 53}}) {
    data {
      Plain
    }
  }

  reviewAttrSix:airtable(data: {ID: {eq: 54}}) {
    data {
      Plain
    }
  }

  reviewDateSix:airtable(data: {ID: {eq: 55}}) {
    data {
      Date(formatString: "M.D.YYYY")
    }
  }

  galleryPhotoOne:airtable(data: {ID: {eq: 26}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 800, height: 600)
          }
        }
      }
      Alt
    }
  }

  galleryCapOne:airtable(data: {ID: {eq: 27}}) {
    data {
      Plain
    }
  }

  galleryPhotoTwo:airtable(data: {ID: {eq: 28}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 800, height: 600)
          }
        }
      }
      Alt
    }
  }

  galleryCapTwo:airtable(data: {ID: {eq: 29}}) {
    data {
      Plain
    }
  }

  galleryPhotoThree:airtable(data: {ID: {eq: 30}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 800, height: 600)
          }
        }
      }
      Alt
    }
  }

  galleryCapThree:airtable(data: {ID: {eq: 31}}) {
    data {
      Plain
    }
  }

  galleryPhotoFour:airtable(data: {ID: {eq: 32}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 800, height: 600)
          }
        }
      }
      Alt
    }
  }

  galleryCapFour:airtable(data: {ID: {eq: 33}}) {
    data {
      Plain
    }
  }

  galleryPhotoFive:airtable(data: {ID: {eq: 34}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 800, height: 600)
          }
        }
      }
      Alt
    }
  }

  galleryCapFive:airtable(data: {ID: {eq: 35}}) {
    data {
      Plain
    }
  }

  galleryPhotoSix:airtable(data: {ID: {eq: 36}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 800, height: 600)
          }
        }
      }
      Alt
    }
  }

  galleryCapSix:airtable(data: {ID: {eq: 37}}) {
    data {
      Plain
    }
  }

  galleryPhotoSeven:airtable(data: {ID: {eq: 41}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 800, height: 600)
          }
        }
      }
      Alt
    }
  }

  galleryCapSeven:airtable(data: {ID: {eq: 42}}) {
    data {
      Plain
    }
  }

  galleryPhotoEight:airtable(data: {ID: {eq: 43}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 800, height: 600)
          }
        }
      }
      Alt
    }
  }

  galleryCapEight:airtable(data: {ID: {eq: 44}}) {
    data {
      Plain
    }
  }

  galleryPhotoNine:airtable(data: {ID: {eq: 45}}) {
    data {
      File {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 800, height: 600)
          }
        }
      }
      Alt
    }
  }

  galleryCapNine:airtable(data: {ID: {eq: 46}}) {
    data {
      Plain
    }
  }

}  
`;