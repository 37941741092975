import React from "react";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline"
import { StaticImage } from "gatsby-plugin-image"

const getCurrentYear = () => {
    return new Date().getFullYear();
  };

const Footer = () => (
<footer>
  <div className="bg-gray-100">
    <div className="container pt-12">
        <div>
        <p className="text-center">
            <a href="tel:2168005579"><PhoneIcon className="h-8 w-8 text-blue-500 mr-8 inline-block" aria-label="Call CDC Transportation." /></a>
            <a href="mailto:cdctransportation@gmail.com"><MailIcon className="h-8 w-8 text-blue-500 mr-8 inline-block" aria-label="Email CDC Transportation." /></a>
            <a href="https://www.instagram.com/CDCTransportation/" target="_blank" rel="noopener noreferrer">
            <StaticImage 
            src="../images/instagram.svg"
            alt="Instagram."
            width={28}
            className="inline-block"
            />
            </a></p>
        </div>
    
    

    <div className="max-w-md mx-auto mt-12 border-t border-gray-200 py-10">
    <p className="text-center font-bold">{getCurrentYear()} &copy; CDC Transportation</p>
    <p className="text-center">Website designed and developed by <a href="https://arisawilliams.com" target="_blank" rel="noopener noreferrer">Arisa Williams</a></p>
    
    </div>
    </div>
  </div>
</footer>
)
export default Footer;