import React from "react";
import { Helmet } from "react-helmet"

const Meta = () => (
    <Helmet>
    <meta charSet="utf-8" />
    <title>CDC Transportation</title>
    <meta name="title" content="CDC Transportation" />
    <meta name="description" content="CDC Transportation provides private transportation services to customers in the Greater Cleveland area." />
    
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://1cdctransportation.com" />
    <meta property="og:title" content="CDC Transportation | Elyria, Ohio" />
    <meta property="og:description" content="CDC Transportation provides private transportation services to customers in the Greater Cleveland area." />
    <meta property="og:image" content="https://1cdctransportation.com/og.png" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:alt" content="CDC Transportation." />
    <meta property="fb:app_id" content="428886365084324" />
    
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://1cdctransportation.com" />
    <meta property="twitter:title" content="CDC Transportation | Elyria, Ohio" />
    <meta property="twitter:description" content="CDC Transportation provides private transportation services to customers in the Greater Cleveland area." />
    <meta property="twitter:image" content="https://1cdctransportation.com/og.png" />
    <meta property="twitter:image:alt" content="CDC Transportation." />
    </Helmet>
)
export default Meta;